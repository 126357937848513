<template>
  <div class="containerx">
    <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
      <div class="title">导出日志</div>
      <div style="display: flex;">
        <div @click="handleExport()" style="cursor: pointer;" class="add">日志导出</div>
        <!-- <div class="back">返回</div> -->
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item,index) in Data" :key="index">
        <div class="text">导出对象：{{item.exportLocation}}</div>
        <div class="name">导出人：{{item.exporter}}</div>
        <div class="date">{{item.gmtCreate}}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination @size-change="sizeChange" @current-change="currentChange" style="color: white" background
        :page-sizes="[10, 15, 20]" :current-page="page" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tableData: [{
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }, {
          name: 'XXXXXXXXXXXX公司'
        }],
        page: 1,
        size: 10,
        total: 0,
        Data:[]
      }
    },
    created() {
      this.info()
    },
    methods: {
      info() {
        var obj = {
          page: this.page,
          size: this.size
        }
        this.$ajax('eplog', 'post', obj).then(res => {
          console.log(res);
          this.total = res.data.total
          this.Data = res.data.records
        })
      },
      sizeChange(e) {
        this.size = e
        this.info()
      },
      currentChange(e) {
        this.page = e
        this.info()
      },
      // 导出日志
      handleExport() {
        var obj = {
          page: this.page,
          size: this.size
        }
        this.$newPost('/export/log/export', obj).then(res => {
          console.log(res)
          window.open(res.data.excelUrl)
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .containerx {
    color: #ffffff;
    overflow: auto;
    background-color: #192534;
    padding: 1rem 0;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .btngrp2 {
    margin-left: 1rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;

    .item {
      width: 10rem;
      height: 4.6rem;
      line-height: 4.6rem;
      text-align: center;
      border: 1px solid #2BC9DE;
      font-size: 1.6rem;
      color: #FFFFFF;
      background: rgba(43, 222, 201, 0.6);
      margin-right: 1.6rem;
    }

    .item-c {
      width: 10rem;
      height: 4.6rem;
      line-height: 4.6rem;
      text-align: center;
      border: 1px solid #2BC9DE;
      font-size: 1.6rem;
      color: #FFFFFF;
      background-color: rgba(43, 222, 201, 0.6);
      margin-right: 1.6rem;
    }
  }

  .btngrp {
    margin-left: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .btn1 {
      width: 15rem;
      height: 8.4rem;
      line-height: 8.4rem;
      text-align: center;
      background: rgba(43, 201, 222, 0.6);
      font-size: 1.8rem;
    }

    .btn2 {
      width: 15rem;
      height: 8.4rem;
      line-height: 8.4rem;
      text-align: center;
      background: rgba(43, 222, 201, 0.6);
      font-size: 1.8rem;
      margin-left: 3rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .add {
    background: #2BC9DE;
    border-radius: 0.5rem;
    width: 9.5rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    font-size: 1.8rem;
    color: #FFFFFF;
    margin-right: 1rem;
  }

  .back {
    border: 1px solid #1CB8B8;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    color: rgba(28, 184, 184, 1);
    font-size: 1.4rem;
    margin-right: 2rem;
  }

  .list {
    width: 154.6rem;
    height: 50rem;
    margin: 0 auto;
    margin-top: 1rem;

    .item {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background: rgba(43, 222, 201, 0.6);
	  background: rgba(162, 162, 162, 0.1);
      font-size: 1.4rem;
    }
    .item:nth-child(2n){
      background-color: rgba(14, 242, 242, 0.25);
    }
    .text {
      width: 60%;
      text-indent: 2rem;
    }

    .name {
      width: 25%;
    }

    .date {
      width: 15%;
    }

  }
</style>
